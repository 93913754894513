.ai-message-box {
  margin: 10px 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.document-container {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 5px;
}

.document-container b{
  color: var(--white-color);
}

.document-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  background-color: #f0f0f0;
}

.response-container {
  border-radius: 5px;
}

.response-container p {
  position:absolute;
  margin: 0;
  color: var(--text-secondary);
  position: relative; 
}

.breadcrumb-container {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin: 10px 0;
}

.breadcrumb-item {
  flex: 1 1 calc(16.66% - 10px);
  max-width: 412.5px;
}

.breadcrumb-button {
  border: 1px solid #000000;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
}

.breadcrumb-button:hover {
  background-color: var(--red-color);
  color: white;
}

.response-container small {
  display: block; 
  margin-bottom: 5px;
  color: #666;
}

.response-header {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  margin: 0;
  padding: 0;
}

.message-metadata-container {
  text-align: left;
  color: var(--white-color);
}

.message-metadata-container small {
  color: transparent;
  font-style: italic;
  opacity: 0.5;
}

.title-ai-answer{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 6px;
  width: 100%;
}

.answer-logo {
  max-width: 24px; 
  margin-right: 4px; 
}

.answer-title {
  color: var(--text-primary);
  font-size: 18px; 
  margin: 0; 
  font-weight: 600;
}

@media (max-width: 768px) {
  .response-header{
    height: 40px;
  }
  .response-container p{
    font-size: 14px;
  }
  .answer-title{
    font-size: 16px;
  }
}