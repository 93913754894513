.header {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  position: relative; 
  background: linear-gradient(to bottom, var(--header-top-color), var(--header-bottom-color));
  border-bottom: 1px solid var(--border-default);
  padding: 14px 40px;
  height: 70px;
}

.sidebar-toggle-container{
  display: flex;
  gap: 8px;
}

.header-logo {
  max-width: 44px; 
  margin-right: 4px; 
}

.title-subtitle-header{
  display: flex;
  align-items: center;
}

.header-title {
  color: var(--header-text); 
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

.header-subtitle {
  color: var(--header-text); 
  font-size: 16px; 
  font-weight: 300;
  margin: 0 0 0 10px;
}

.action-button-container{
  display: flex;
  gap: 8px;
}

/* Contenedor de dropdown */
.dropdown-container {
  position: relative;
}

.three-dots-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--icon-action-text);
  padding: 10px;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  width: 200px;
  background-color: var(--dropdown-bg);
  border: 1px solid var(--border-default);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  color: var(--dropdown-option-text);
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  border-radius: 6px;
}

.dropdown-item:hover {
  background-color: var(--dropdown-option-bg-hover);
  color: var(--dropdown-option-text-hover);
}


@media (max-width: 768px) {
  .header{
    padding: 16px 6%;
  }
  .action-button-container > .action-hook-button {
    display: none;
  }
  .dropdown-container {
    display: block;
  }
}

@media (min-width: 769px) {
  .action-button-container > .action-hook-button {
    display: inline-flex;
  }
  .dropdown-container {
    display: none;
  }
}