#btn-cargaperiodica {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--btn-primary-bg);
    color: var(--btn-primary-text);
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 14px;
    height: 48px;
    transition: all 0.3s ease;
}
  
#btn-cargaperiodica:hover {
    background-color: var(--btn-primary-bg-hover);
    color: var(--btn-primary-text-hover);
}
#btn-cargaperiodica img {
    width: 24px;
    height: 24px;
}
  
#btn-cargaperiodica span {
    display: inline-block;
    transition: opacity 0.3s ease;
    max-width: 108px;
}

#btn-cargaperiodica.compressed {
    padding: 8px 12px;
    gap: 0;
  }
  
#btn-cargaperiodica.compressed span {
    display: none;
}

@media (max-width: 620px) {
    #btn-cargaperiodica {
        padding: 8px;
        gap: 0; 
    }

    #btn-cargaperiodica span {
        opacity: 0;
        visibility: hidden;
        width: 0;
        overflow: hidden;
    }
    .expanded-content #btn-cargaperiodica{
        width: 42px;
        height: 42px;
    }
    .expanded-content .header-table{
        margin-bottom: 12px !important;
    }
}

  