.new-query-button {
  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: var(--btn-primary-bg);
  border: none;
  color: var(--btn-primary-text);
  padding: 12px 16px;
  width: 100%;
  height: 46px;
  cursor: pointer;
  text-align: center; 
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-weight: 500;
}

.new-query-button .icon {
  margin-right: 6px;
  font-size: 14px;
}

.new-query-button:hover,
.new-query-button.active {
  background-color: var(--btn-primary-bg-hover);
  color: var(--btn-primary-text-hover);
}

.new-query-button.loading {
  background-color: var(--btn-primary-bg-disabled);
  color: var(--btn-primary-text-disabled);
  cursor: not-allowed;
  pointer-events: none; 
  opacity: 0.7; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-modal-outside);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.modal-body {
  margin-top: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-btn, .primary-btn {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-body {
      padding: 20px 16px;
  }
  .modal-header, .modal-footer {
      padding: 16px;
  }
}