.document-preview {
  border-radius: 8px;
  padding: 18px;
  border: 1px solid var(--border-default);
  background-color: none;
  max-height: 70vh;
  overflow: scroll;
  width: 100%;
}

.document-preview:hover{
  background-color: var(--icon-action-bg-hover);
  border: 1px solid var(--border-default);
}
.document-preview .selected {
  background-color: var(--search-prompt-bg-selected);
  border: 1px solid var(--btn-primary-border-hover);
}

.metadata {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 200;
}

.metadata div {
  margin-bottom: 8px;
}

.metadata strong {
  color: var(--text-primary);
}

.metadata span {
  color: var(--gray-500);
}

.document-content {
  margin-top: 12px;
}

.document-content p {
  margin: 0;
  font-size: 1em;
  line-height: 1.5;
  color: var(--white-color);
}

.document-button {
  padding: 11px 14px;
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-text);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  transition: transform 3s ease;
  margin-right: 10px;
}
.document-button:hover{
  background-color: var(--btn-primary-bg-hover);
  color: var(--btn-primary-bg);
}

button:focus {
  outline: none;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: var(--white-color);
  border: none;
  font-size: 2em;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: var(--dark-red-color);
}

.close-button:focus {
  outline: none;
}

.document-preview-content p{
  font-size: 1em;
  line-height: 1.5;
  color: var(--white-color);
  text-align: justify;
}

.document-preview-content {
  opacity: 0; /* Inicialmente colapsado */
  transition: opacity 0.5s ease-in-out; /* Transición para la opacidad */
}

.document-preview-content.expanded {
  margin-top: 10px; /* Espaciado al expandir */
  opacity: 1; /* Visibilidad completa cuando está expandido */
}

.document-preview-content iframe{
  width: 100%;
  height: 480px;
  margin-top: 20px;
}

.document-preview-header {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
}

.document-header-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.document-source {
  font-size: 14px;
  color: var(--text-secondary);
}

.document-preview h2 {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 10px;
  text-align: left;
}

.custom-checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px; 
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  top: -12px;
}

.custom-checkbox-container input {
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid var(--gray-500); 
  border-radius: 4px;
  background-color: transparent;
}

.custom-checkbox-container input:checked ~ .checkmark {
  background-color: var(--checked-color); 
  border-color: var(--checked-color); 
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox-container .checkmark:after {
  left: 7px;
  top: 3px; 
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.preview-area .document-row{
  margin-bottom: 14px;
  align-items: normal;
}

/* Contenedor de tags */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

/* Estilo base para los tags */
.tag {
  padding: 1px 10px;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid var(--border-default)
}

/* Tags de texto */


.tag-text:hover {
  background-color: var(--btn-secondary-bg-hover);
  color:var(--text-primary);
}
/* Tags de enlaces */
.tag-link {
  color: var(--btn-primary-bg-hover); /* Verde oscuro */
  text-decoration: none;
}

.tag-link:hover {
  text-decoration: underline; /* Subrayado al pasar el mouse */
}
