.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-modal-outside);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    box-sizing: border-box;
}
.modal-consulta {
    background-color: var(--bg-modal);
    border-radius: 16px;
    max-width: 600px !important;
    width: 100%;
    height: auto;
    padding: 0;
    border: 1px solid var(--border-default);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); 
    box-sizing: border-box; 
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 28px;
    border-bottom: 1px solid var(--border-default);
    border-radius: 16px 16px 0 0;
}

.modal-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--text-primary);
}

.modal-header .close-btn {
    background: none;
    border: none;
    color: var(--icon-reaction-text);
    font-size: 18px;
    cursor: pointer;
    width: 36px;
    height: 36px;
}

.close-btn svg {
    margin-top: 5px;
    color: black;
}
  
.modal-body {
    padding: 28px 28px 32px 28px;
    margin: 0;
    display: grid;
    gap: 26px;
}

.modal-question {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
    line-height: 24px;
}

.description-title{
    font-size: 14px;
    color: var(--text-secondary);
    line-height: 20px;
    font-weight: 300;
}

.opcional {
    font-size: 12px;
    color: var(--gray-400);
    align-items: center;
}

.freetext{
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
    line-height: 24px;
    display: flex;
    gap: 4px;
}

.feedback-options-consulta {
    display: flex;
    flex-wrap: wrap; 
    gap: 12px;
    margin: 8px 0 0 0 !important;
}

.feedback-option-consulta {
    display: block;
    border: 1px solid var(--options-border);
    padding: 12px 15px !important;
    border-radius: 8px;
    cursor: pointer;
    background-color: transparent;
    color: var(--options-text);
    width: 48.7%;
    white-space: none !important;
}
.feedback-option-consulta div {
    display: flex;
    flex-direction: column;
}
.feedback-option-consulta span {
    margin-top: 5px;
}

.feedback-option-consulta .icon-option{
    width: 28px;
    height: 28px;
    padding: 0;
}

.option-description {
    font-size: 13px;
    color: inherit;
    font-weight: 300;
    line-height: 18px;
}

.feedback-option-consulta:hover {
    background-color: var(--options-bg-hover);
    color: var(--options-text-hover);
}

.feedback-option-consulta.selected {
    border: 1px solid var(--options-border-selected);
    background-color: var(--options-bg-selected);
    color: var(--options-text-selected);
}

textarea {
    width: 100%;
    padding: 12px 14px;
    border-radius: 6px;
    border: 1px solid var(--textfield-border);
    background-color: var(--textfield-bg);
    color: var(--text-primary);
    font-size: 14px;
    margin-top: 12px;
    resize: vertical;
    height: 44px;
}

textarea::placeholder {
    color: var(--text-placeholder);
}  


.modal-footer {
    display: flex;
    justify-content:  flex-end;
    padding: 16px 28px;
    border-top: 1px solid var(--border-default);
    margin-top: 0;
}

.primary-btn {
    background-color: var(--btn-primary-bg);
    color: var(--btn-primary-text);
    border-radius: 10px;
    padding: 12px 22px;
    font-size: 16px;
    line-height: 22px;
}
.primary-btn:hover {
    background-color: var(--btn-primary-bg-hover);
    color: var(--btn-primary-text-hover);
}

button.cancel-btn {
    background-color: transparent;
    color: var(--btn-secondary-text);
    border: 1px solid var(--btn-secondary-border);
    border-radius: 10px;
    padding: 12px 22px;
    font-size: 16px;
    line-height: 22px;
}

.modal-footer button.cancel-btn:hover {
    background-color: var(--btn-secondary-bg-hover);
    border: 1px solid var(--btn-secondary-border-hover);
    color: var(--btn-secondary-text-hover);
}

.bot-description {
    font-size: 13px;
    color: var(--text-secondary);
    margin-top: 4px;
    text-align: center;
}
.bot-description.selected {
    color: var(--options-text-selected);
}

@media (max-width: 768px) {
    .modal-overlay {
        padding: 14px; 
    }

    .modal-consulta {
        width: 100%;
        max-width: unset; 
        border-radius: 12px; 
        margin: 0;
        height: 100%;
    }

    .modal-body {
        overflow-y: auto; 
        flex: 1; 
    }
    .feedback-option-consulta{
        width: 48%;
        padding: 16px;
    }
    .feedback-option-consulta span{
        line-height: 23px;
        margin-top: 8px;
    }
}