.chat-content.full-width {
  margin-left: 0;
}

.sidebar-hidden .chat-content {
  margin-left: 0;
}

.chat {
    display: flex;
}
  
.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--bg-surface);
  margin-left: 240px;
  width: auto;
}

.chat-container {
  display: flex;
  height: 100vh;
  background-color: var(--bg-surface);
  transition: margin-left 0.3s ease;
}
  
.main-content {
  width: auto;
  margin-left: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}
  
.chat-preview-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 5px 0;
  padding: 0;
  background-color: var(--bg-surface);
  overflow-y: auto;
  transition: flex 3s ease, max-width 3s ease; 
  width: 100%; 
}

.full-width {
  flex-direction: column;
  width: 100%;
}

.chat-area {
  flex: 1; 
  min-height: 70vh;
  max-height: 70vh;
}

.preview-area {
  flex: 0 0 50%; 
  max-width: 50%;
}

.preview-area.hidden {
  display: none; 
}

@media (max-width: 768px) {
  .chat-content {
    margin-left: 0; 
  }
}