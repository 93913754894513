  
.citation-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: var(--bg-default-hover);
    font-size: 12px;
    font-weight: bold;
    position: relative;

  }

.citation-icon.has-tooltip {
    cursor: pointer; /* Cambia el cursor a un puntero para indicar que es interactivo */
}

  
.citation-icon.has-tooltip::after {
    content: attr(data-tooltip); /* Usa el texto de la propiedad data-tooltip como contenido */
    position: absolute;
    bottom: 120%; /* Ubica el tooltip encima del icono */
    left: 50%;
    transform: translateX(0%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s; /* Efecto suave para el tooltip */
  }
  
.citation-icon.has-tooltip:hover::after {
    opacity: 1;
    visibility: visible;
  }