.loader{
    padding: 10rem 0;
    animation: spin 2s linear infinite; /* Aplicar la animación de rotación */
    align-items: center;
}
.loader svg{
    width: 150px;
}

.loader path {
    fill: var(--loader-color);
    stroke: var(--loader-color);

}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }