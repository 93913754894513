.messages-placeholder {
    text-align: center;
    padding-top: 5%;
  }
  
  .messages-placeholder-header {
    margin-bottom: 20px;
  }
  
  
  .messages-placeholder-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .messages-placeholder-logo img {
    height: 100px;
    width: auto;
    margin-bottom: 20px;
  }
  
  .messages-placeholder-logo h1 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .messages-placeholder-main-content h2 {
    font-size: 26px;
    font-weight: normal;
    margin-top: 20px;
  }
  
  .messages-placeholder-main-content {
    margin-top: 20px;
  }
  
  .messages-placeholder-main-question {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .messages-placeholder-collection-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .messages-placeholder-questions-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  
  .messages-placeholder-toggle-form {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border: 1px var(--border-default);
    transition: transform 0.2s;
  }
  
  .messages-placeholder-toggle-form button {
    cursor: pointer;
    background-color: white;
    padding: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .messages-placeholder-toggle-form:hover {
    transform: scale(1.05);
  }
  
  .messages-placeholder-question-card:hover {
      transform: scale(1.05);
  }
  .messages-placeholder-question-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .messages-placeholder-question-card:hover {
    transform: scale(1.05);
  }
  
  .messages-placeholder-new-query {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin: 0 auto; /* Esto centra el contenedor horizontalmente */
  }
  
  .messages-placeholder-new-query button {
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    padding: 20px 24px;
    margin-top: 20px;
  }
