.login-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
.content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/images/bg-login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

footer {
    width: 100%;
}

.modal-login{
  padding: 50px 55px;
  border: 1px solid var(--border-table);
  background-color: var(--bg-modal);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
}

.title-login {
    font-family: 'Inter', sans-serif;
    color: var(--text-primary);
    margin-bottom: 10px;
    font-size: 28px;
    text-align: center;
    height: 52px;
}
.subtitle-login{
    color: var(--text-primary);
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 28px;
}

label {
    color: var(--text-secondary);
    display: block;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

input.form-log {
    height: 52px;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    margin-top: 4px;
    margin-bottom: 20px;
    border: 0.5px solid var(--textfield-border);
    font-size: 16px;
}

input.form-log:active, input.form-log:focus {
    border: 0.5px solid var(--red-color);
}

.login_cta {
    font-family: 'Inter', sans-serif;
    color: var(--btn-primary-text);
    background-color: var(--btn-primary-bg);
    border-radius: 10px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
}

.login_cta:hover {
    background-color: var(--btn-primary-bg-hover);
    color: var(--btn-primary-text-hover);
}

.col-sm-6.login {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.login_forgot {
    margin-top: 18px;
    text-align: center;
    font-size: 16px;
}

.login-logo {
    display: block;
    margin: 0 auto;
    height: 75px;
    width: auto;
    margin-bottom: 30px;
}

.error{
    font-size: 14px;
    color: var(--red-color);
}


@media (min-width: 768px) and (max-width: 1024px) {
    .col-sm-6 {
        width: 60%;
    }
    .col-sm-6.login {
        margin-bottom: 300px;
    }
}

@media (max-width: 768px) {
    .col-sm-6.login {
        margin-bottom: 0px;
    }
}
