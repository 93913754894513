.documents-selector{
    display: flex;
}

.documents-selector-icons{
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}
.chat-tools-dropdown {
    height: fit-contentsuper;
    width: 50%;
}

.dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* .dropdown-menu {
    position: absolute;
    border: 1px solid #ddd;
    padding: 10px;
    width: 1000px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  

.document-input {
    width: 20px;
} */