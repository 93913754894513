@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --blue-color: #3b99fc;
  --red-color: #f83c4c;
  --dark-red-color: #e62e43;

  --gray-50: #F7F7F8;
  --gray-100: #EEEEF0;
  --gray-150: #E4E4E7;
  --gray-200: #DADADD;
  --gray-300: #B9BAC0;
  --gray-400: #93949D;
  --gray-500: #767681;
  --gray-600: #606169;
  --gray-700: #4E4E56;
  --gray-800: #434349;
  --gray-900: #3B3C3F;
  --gray-925: #2F2F2F;
  --gray-950: #262629;

  --pomelo-50: #FEF2F3;
  --pomelo-100: #FFE1E3;
  --pomelo-200: #FFC9CD;
  --pomelo-300: #FEA3AA;
  --pomelo-400: #FB6E79;
  --pomelo-500: #F2414F;
  --pomelo-600: #E02231;
  --pomelo-700: #BC1926;
  --pomelo-800: #9C1822;
  --pomelo-900: #811B23;
  --pomelo-950: #46090E;

  --doctors-green: #52DFB8;
  --doctors-green-dark: #40b292;
  --doctors-blue: #19356b;


  --base-white: #FFFFFF;
  --base-black: #191919;

  --green-color: #00c853;
  --dark-green-color: #6b746d;
  --transition: all 300ms ease-in-out;
}

[data-theme='dark'] {
  --border-default: var(--gray-500);
  --border-focus: var(--gray-50);
  --border-table: var(--gray-700);

  --bg-default: var(--gray-900);
  --bg-sidebar: var(--base-black);
  --bg-surface: var(--gray-950);
  --bg-modal: var(--gray-925);
  --bg-default-hover: var(--gray-700);
  --bg-modal-outside: rgba(0, 0, 0, 0.70);
  --bg-internalchat: var(--gray-900);
  --bg-subcategory: var(--gray-925);
  --bg-expanded-row: var(--gray-925);
  --bg-tr-hover: var(--gray-800);
  --bg-th-subtable: var(--gray-700);

  --text-primary: var(--base-white);
  --text-secondary: var(--gray-300);
  --text-disabled: var(--gray-950);
  --text-link: var(--base-white);
  --text-placeholder:  var(--gray-400);
  --text-terciary: var(--gray-500);

  --dropdown-bg: var(--gray-900);
  --dropdown-border: var(--gray-50);
  --dropdown-option-text: var(--gray-200);
  --dropdown-option-bg-hover: var(--gray-800);
  --dropdown-option-text-hover: var(--base-white);

  --icon-reaction-text: var(--gray-200);
  --icon-reaction-text-hover: var(--base-white);
  --icon-reaction-bg-hover: var(--gray-900);

  --icon-action-text: var(--gray-200);
  --icon-action-border: var(--gray-600);
  --icon-action-text-hover: var(--base-white);
  --icon-action-bg-hover: var(--gray-900);
  --icon-action-text-selected: var(--base-white);
  --icon-action-border-selected: var(--base-white);

  --sidebar-option-text: var(--gray-200);
  --sidebar-option-bg-hover: var(--gray-900);
  --sidebar-option-text-hover: var(--base-white);
  --sidebar-menu-text: var(--base-white);
  --sidebar-menu-bg-hover: var(--gray-900);
  --sidebar-search-border: var(--gray-700);
  --sidebar-search-bg: var(--gray-950);

  --switch-bg-off: rgba(var(--base-white-rgb), 0.0);
  --switch-bg-on: var(--pomelo-500);
  --switch-border-off: var(--gray-200);
  --switch-ball: var(--base-white);

  --btn-primary-bg: var(--pomelo-500);
  --btn-primary-border: var(--pomelo-500);
  --btn-primary-text: var(--base-white);
  --btn-primary-bg-hover: var(--pomelo-600);
  --btn-primary-border-hover: var(--pomelo-600);
  --btn-primary-text-hover: var(--base-white);
  --btn-primary-bg-disabled: var(--gray-700);
  --btn-primary-border-disabled:  var(--gray-950);
  --btn-primary-text-disabled:  var(--gray-950);

  --btn-secondary-border: var(--gray-600);
  --btn-secondary-text: var(--gray-100);
  --btn-secondary-bg-hover: var(--gray-900);
  --btn-secondary-border-hover: var(--gray-200);
  --btn-secondary-text-hover: var(--base-white);
  --btn-secondary-border-disabled: var(--gray-900);
  --btn-secondary-text-disabled: var(--gray-900);

  --answer-bg: var(--pomelo-700);
  --answer-text: var(--base-white);

  --search-bg: var(--gray-900);
  --search-border: var(--gray-700);
  --search-icon: var(--gray-600);
  --search-icon-hover: var(--base-white);
  --search-icon-selected: var(--base-white);
  --search-icon-border-selected: var(--pomelo-500);
  --search-icon-bg-selected: var(--pomelo-400);
  --search-btn-bg-active: var(--pomelo-500);
  --search-btn-bg-hover: var(--pomelo-600);
  --search-btn-icon: var(--base-white);
  --search-btn-icon-disabled: var(--gray-500);
  --search-btn-bg-disabled: var(--gray-700);
  --searcn-prompt-text: var(--gray-200);
  --searcn-prompt-border: var(--gray-600);
  --searcn-prompt-text-hover: var(--base-white);
  --search-prompt-bg-hover: var(--gray-900);
  --searcn-prompt-border-selected: var(--pomelo-500);
  --search-prompt-bg-selected: rgba(242, 61, 76, 0.5);
  --search-dropdown-option-text: var(--gray-200);
  --search-dropdown-bg: var(--gray-900);
  --search-dropdown-border: var(--gray-700);
  --search-dropdown-border-selected: rgba(242, 61, 76, 0.5);
  --search-dropdown-bg-selected: var(--gray-800);
  --search-dropdown-tag-text: var(--gray-900);
  --search-dropdown-tag-background: var(--gray-400);
  --search-dropdown-tag-icon: var(--gray-700);
  --search-tag-text: var(--gray-50);
  --search-tag-icon: var(--gray-300);
  --search-tag-border: var(--gray-600);

  --modal-textarea-border: var(--gray-500);
  --modal-textarea-border-selected: var(--gray-300);
  --modal-border: var(--gray-500);

  --textfield-border: var(--gray-600);
  --textfield-bg: var(--gray-925);
  --textfield-border-hover: var(--base-white);
  --textfield-border-selected: var(--base-white);
  --textfield-icon: var(--gray-50);

  --options-border: var(--gray-600);
  --options-border-selected: var(--pomelo-500);
  --options-bg-hover: var(--gray-900);
  --options-bg-selected: rgba(242, 61, 76, 0.5);
  --options-icon: var(--base-white);
  --options-text: var(--gray-300);
  --options-text-hover: var(--base-white);
  --options-text-selected: var(--base-white);

  --nolike-disliked-color: var(--pomelo-900);
  --bookmark-marked-color: var(--blue-color);
  --like-liked-color: var(--green-color);
  --copy-message-copied-color: var(--pomelo-500);
  --cite-documents-cited-color: var(--pomelo-500);
  --loader-color: var(--pomelo-100)
}

[data-theme='light'] {
  --border-default: var(--gray-100);
  --border-focus: var(--gray-800);
  --border-table: var(--gray-200);

  --bg-default: var(--gray-100);
  --bg-sidebar: var(--gray-50);
  --bg-surface: var(--base-white);
  --bg-modal: var(--base-white);
  --bg-default-hover: var(--gray-200);
  --bg-modal-outside: rgba(0, 0, 0, 0.50);
  --bg-internalchat: var(--gray-50);
  --bg-subcategory: var(--gray-150);
  --bg-expanded-row: var(--base-white);
  --bg-tr-hover: var(--gray-100);
  --bg-th-subtable: var(--gray-300);

  --text-primary: var(--base-black);
  --text-secondary: var(--gray-700);
  --text-disabled: var(--gray-100);
  --text-link: var(--base-black);
  --text-placeholder:  var(--gray-400);
  --text-terciary: var(--gray-300);

  --dropdown-bg: var(--base-white);
  --dropdown-border: var(--gray-200);
  --dropdown-option-text: var(--gray-800);
  --dropdown-option-bg-hover: var(--gray-100);
  --dropdown-option-text-hover: var(--base-black);

  --icon-reaction-text: var(--gray-500);
  --icon-reaction-text-hover: var(--base-black);
  --icon-reaction-bg-hover: var(--gray-100);

  --icon-action-text: var(--gray-800);
  --icon-action-border: var(--gray-200);
  --icon-action-text-hover: var(--base-black);
  --icon-action-bg-hover: var(--gray-50);
  --icon-action-text-selected: var(--base-black);
  --icon-action-border-selected: var(--base-black);

  --sidebar-option-text: var(--gray-900);
  --sidebar-option-bg-hover: var(--gray-100);
  --sidebar-option-text-hover: var(--base-black);
  --sidebar-menu-text: var(--base-black);
  --sidebar-menu-bg-hover: var(--gray-100);
  --sidebar-search-border: var(--gray-200);
  --sidebar-search-bg: var(--base-white);

  --switch-bg-off: var(--gray-200);
  --switch-bg-on: var(--pomelo-500);
  --switch-border-off: var(--gray-200);
  --switch-ball: var(--base-white);

  --btn-primary-bg: var(--pomelo-500);
  --btn-primary-border: var(--pomelo-500);
  --btn-primary-text: var(--base-white);
  --btn-primary-bg-hover: var(--pomelo-600);
  --btn-primary-border-hover: var(--pomelo-600);
  --btn-primary-text-hover: var(--base-white);
  --btn-primary-bg-disabled: var(--gray-300);
  --btn-primary-border-disabled:  var(--gray-50);
  --btn-primary-text-disabled:  var(--gray-50);

  --btn-secondary-border: var(--gray-200);
  --btn-secondary-text: var(--gray-800);
  --btn-secondary-bg-hover: var(--gray-100);
  --btn-secondary-border-hover: var(--gray-200);
  --btn-secondary-text-hover: var(--base-black);
  --btn-secondary-border-disabled: var(--gray-100);
  --btn-secondary-text-disabled: var(--gray-500);

  --answer-bg: var(--pomelo-100);
  --answer-text: var(--pomelo-950);

  --search-bg: var(--gray-50);
  --search-border: var(--gray-100);
  --search-icon: var(--gray-400);
  --search-icon-hover: var(--base-white);
  --search-icon-selected: var(--base-white);
  --search-icon-border-selected: var(--pomelo-500);
  --search-icon-bg-selected: var(--pomelo-200);
  --search-btn-bg-active: var(--pomelo-500);
  --search-btn-bg-hover: var(--pomelo-600);
  --search-btn-icon: var(--base-white);
  --search-btn-icon-disabled: var(--gray-50);
  --search-btn-bg-disabled: var(--gray-200);
  --searcn-prompt-text: var(--gray-800);
  --searcn-prompt-border: var(--gray-200);
  --searcn-prompt-text-hover: var(--base-black);
  --search-prompt-bg-hover: var(--gray-100);
  --searcn-prompt-border-selected: var(--pomelo-500);
  --search-prompt-bg-selected: var(--pomelo-200);
  --search-dropdown-option-text: var(--gray-500);
  --search-dropdown-bg: var(--base-white);
  --search-dropdown-border: var(--gray-200);
  --search-dropdown-border-selected: var(--gray-300);
  --search-dropdown-bg-selected: var(--gray-50);
  --search-dropdown-tag-text: var(--gray-50);
  --search-dropdown-tag-background: var(--gray-600);
  --search-dropdown-tag-icon: var(--gray-300);
  --search-tag-text: var(--gray-950);
  --search-tag-icon: var(--gray-700);
  --search-tag-border: var(--gray-200);

  --modal-textarea-border: var(--gray-200);
  --modal-textarea-border-selected: var(--pomelo-400);
  --modal-border: var(--gray-200);

  --textfield-border: var(--gray-200);
  --textfield-border-hover: var(--gray-900);
  --textfield-border-selected: var(--gray-900);
  --textfield-icon: var(--gray-800);

  --options-border: var(--gray-200);
  --options-border-selected: var(--pomelo-500);
  --options-bg-hover: var(--gray-100);
  --options-bg-selected: rgba(242, 61, 76, 0.7);
  --options-icon: var(--gray-800);
  --options-text: var(--gray-950);
  --options-text-hover: var(--base-black);
  --options-text-selected: var(--base-white);

  --nolike-disliked-color: var(--pomelo-900);
  --bookmark-marked-color: var(--blue-color);
  --like-liked-color: var(--green-color);
  --copy-message-copied-color: var(--pomelo-500);
  --cite-documents-cited-color: var(--pomelo-500);
  --loader-color: var(--pomelo-100)

}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 10px;
}
body {
  font-size: 1.6rem;
  line-height: 1.7;
  font-family: "Inter", sans-serif;
  color: var(--black-color);
}

p {
  color: var(--gray-color);
  font-family: "Inter", sans-serif;
}
p.fs-17-black {
  font-family: "Inter", sans-serif;
  color: var(--black-color);
  font-size: 16px;
  font-weight: 600;
}

.col-sm-6 {
  margin-top: 140px;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  border: 0.5px solid var(--dark-gray-color);
  border-radius: 12px;
}

/* resets */
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--a-color);
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

a:hover {
  color: var(--a-hover-color);
}

a.button.cta_generar_informe:hover {
  color: var(--white-color);
}

button {
  outline: 0;
  border: none;
  font-family: "Inter", sans-serif;
  background-color: transparent;
  cursor: pointer;
}

img {
  width: 100%;
  display: block;
}

input {
  border: none;
  outline: 0;
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
}


.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

/* backgrounds */
.bg-red {
  background-color: var(--red-color);
}
.bg-black {
  background-color: var(--black-color);
}
.bg-white {
  background-color: var(--white-color);
}

/* text */
.text-black {
  color: var(--black-color);
}
.text-white {
  color: var(--white-color);
}
.text-purple {
  color: var(--purple-color);
}
.text-light-black {
  color: var(--light-black-color);
}
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-italic {
  font-style: italic;
}

/* font weights */
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}

/* font sizes */
.fs-15 {
  font-size: 1.5rem;
}
.fs-16 {
  font-size: 1.6rem;
}
.fs-17 {
  font-size: 16px;
}
.fs-18 {
  font-size: 1.8rem;
}
.fs-20 {
  font-size: 2rem;
}
.fs-22 {
  font-size: 2.2rem;
}
.fs-24 {
  font-size: 2.4rem;
}
.fs-26 {
  font-size: 2.6rem;
}

/* letter spacing */
.ls-1 {
  letter-spacing: 1px;
}
.ls-2 {
  letter-spacing: 2px;
}

.flex {
  display: flex;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-sb {
  justify-content: space-between;
}
.flex-c {
  justify-content: center;
}
.grid {
  display: grid;
}
.container {
  max-width: 1440px;
  margin: 0 auto;
}

/* section title */
.section-title h2 {
  font-weight: 600;
  text-transform: uppercase;
  padding: 18px 0 24px 0;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .col-sm-6 {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .col-sm-6 {
    margin-top: 80px;
    width: 90%;
    padding: 40px 30px;
  }
  h2 {
    font-size: 24px;
  }
}
